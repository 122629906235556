#intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15%;
    padding-top: 5%;
    min-height: 95vh;
  }

  
  canvas {
    width: 200px !important;
  }
  
  .Typist {
    color: var(--lightest-slate);
    text-align: center;
    font-size: 76px;
    margin-top: -30px;
    display: inline-block;

  }
  .Typist .Cursor--blinking {
    color: #FF4C29;;
    padding-left: 4px;
    display: inline-block;

  }
  
  .intro-title {
    font-family: "NTR", sans-serif;
    font-size: 86px;
  }
  .intro-name {
    font-family: "NTR", sans-serif;
    color: #FF4C29;;
    font-weight: bold;
    font-size: 86px;
  }
  
  .intro-subtitle {
    margin-top: -36px;
    font-family: "NTR", sans-serif;
    color: var(--slate);
    font-weight: 300;
    font-size: 55px;
  }
  
  .intro-desc {
    color: var(--slate);
    font-family: "NTR", sans-serif;
    font-size: 20px;
    max-width: 55%;
    padding-bottom: 50px;
  }
  
  .intro-contact {
    font-size: 30px;
    font-family: "NTR", sans-serif;
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #FF4C29;;
    border-radius: 4px;
  }
  
  .intro-contact:hover {
    background-color: var(--lightest-navy);
  }
  
  b {
    font-size: 17px;
  }
  
  @media only screen and (max-width: 600px) {
      #intro {
      min-height: unset;
      padding-left: unset;
      margin: 0;
      align-items: center;
    }
    #sam-picture{
      min-height: 100px;
    }
  
    .intro-title {
      font-size: 46px;
    }
    .intro-name {
      font-size: 46px;
    }
  
    .Typist {
      font-size: 36px;
    }
  
    .intro-subtitle {
      margin: 0;
      font-size: 28px;
      text-align: center;
    }
  
    .intro-desc {
      font-size: 18px;
      text-align: center;
      max-width: unset;
      padding-bottom: 40px;
    }
  
    .intro-contact {
      font-size: 16px;
      align-self: center;
      margin-left: 10%;
    }
  }