#about {
    padding-left: 15%;
    padding-top: 5%;
    padding-right: 25%;
  }
  
  .about-description ul {
    padding-top: 20px;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 10px));
  }
  
  .about-description li {
    position: relative;
    padding-left: 20px;
    font-size: 18px;
    color: var(--lightest-slate);
    padding-bottom: 10px;
  }
  
  .about-description li::before {
    content: "◆   ";
    color: #FF4C29; /* need to fix*/
    position: absolute;

    padding-bottom: 5px;
    left: 0;
  }
  
  /*About content*/
  .about-content {
    display: inline-flex;
    flex-direction: row;
  }
  
  .about-description {
    font-family: "NTR", sans-serif;
    color: var(--lightest-slate);
    max-width: 800px;
    font-size: 20px;
    margin: 0;
  }
  
  .about-description a,
  .about-description b {
    font-size: 19px;
    font-weight: bold;
  }
  
  .about-image {
    padding-left: 40px;
  }
  
  .about-image img {
    max-width: 400px;
    height: auto;
    border-radius: 5px;
    box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
    border: 2px solid #FF4C29; /*need to fix*/
  }
  
  @media only screen and (max-width: 788px) {
    .about-image img {
      max-width: 200px;
    }
  }
  
  .about-image:hover {
    transform: translateY(-7px);
  }
  
  @media only screen and (max-width: 600px) {
    #about {
      height: unset;
      padding-left: unset;
      padding-right: unset;
      padding-top: 30%;
    }
    .about-image img {
      display: none;
    }
  
    .about-content {
      display: unset;
    }
  
}