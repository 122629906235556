html{
  scroll-behavior: smooth;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*Deals with the whole portion of the webpage*/
.App{ 
  background-color: #082032;
  display: flex;
  flex-direction: row;

}

.App-header{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #FF4C29;;
}

/*Section title*/
.section-title{
  color: var(--lightest-slate);
  font-size: 40px;
  font-weight: bold;
}

.section-header{
  display:flex;
  padding-bottom: 42px;
  width: 100vw;
}

/**Might remove later **/
.section-header::after{
  content: "";
  display: flex;
  width: 300px;
  margin-top: 40px;
  height: 1px;
  margin-left: 20px;
  background-color: #FF4C29;
}


/*Creates the fade in and fadeout section of the webpage*/
.fade-in-section{
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible{
  opacity: 1;
  transform: none;
  visibility: visible;

}

/*Conents of the elements of the web-page */

#content{
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  /*padding:80px 200px;*/

}
.rs-steps-horizontal .rs-steps-item-content{
  text-align: left;
}
::selection{
  background: var(--lightest-navy);
}


@media (prefers-reduced-motion: no-preference){
  .App-logo{
    animation: App-logo-spin infinite 15s linear;
  }

}
@media only screen and (max-width:600px){
  .App{
    flex-direction: column;
    padding: 40px 24px;
  }
  #content{
    padding:10px 10px;
    width: 100%;

  }
  .section-header{
    width: unset;

  }
  .section-header::after{
    display: none;
  }
  .section-title{
    font-size: 32px;
  }
}



