#credit{
    display: flex;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 30px;
}
.end-credits{
    font-family: sans-serif;
    color: var(--slate);
    text-align: center;
    width: 100%;
    font-size: 15px;
}